<!-- 动态表单上传 -->
<template>
  <div>
    <slot :open="open" :close="close" />
    <el-dialog title="上传图片" :visible.sync="visible" :close-on-click-modal="false" width="500px" append-to-body>
      <div>
        <div class="inner">
          <div class="tab">
            <el-tooltip class="item" effect="dark" content="粘贴上传" placement="top-start">
              <div @click="currentTab = 'copy'" :class="currentTab === 'copy' && 'active_tab'">
                <img v-if="currentTab === 'copy'" src="https://img.fanxinghuishou.cn/20230912/043746741.png" />
                <img v-else src="https://img.fanxinghuishou.cn/20230912/777867251.png" />
              </div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="选择本地图片" placement="top-start">
              <div @click="currentTab = 'upload'" :class="currentTab === 'upload' && 'active_tab'">
                <img v-if="currentTab === 'upload'" src="https://img.fanxinghuishou.cn/20230912/814145275.png" />
                <img v-else src="https://img.fanxinghuishou.cn/20230912/254382542.png" />
              </div>
            </el-tooltip>
          </div>
          <div class="content" :style="{ border: `${imageUrl ? '1px dashed #4C84FF' : '0px #fff'}`,}">
            <div class="content_copy content_style" v-if="currentTab === 'copy'">
              <p @paste="handlePaste">先点击，再Ctrl+V粘贴图片</p>
            </div>
            <div class="content_upload content_style" v-if="currentTab === 'upload'">
              <p><i class="el-icon-plus"></i>上传图片</p>
              <input @input="fileChange" type="file" name="file" accept="image/*" />
            </div>
          </div>
        </div>
        <div
          class="copy_inner"
          v-loading="loading"
          element-loading-text="上传中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)"
          v-show="imageUrl"
        >
          <img
            class="copy_img"
            v-loading="loading"
            id="copyImg"
            :src="imageUrl"
            />
          <div class="mask">
            <i class="el-icon-view" @click="preview"></i>
            <i class="el-icon-delete" @click="deleteImg"></i>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="visible = false">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import environments from "@/config/url";
import { encryptByDES } from "@/utils/3DES";

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      visible: false,
      
      currentTab: 'copy',
      loading: false,
      imageUrl: ''
    }
  },
  methods: {
    open() {
      this.imageUrl = this.value
      this.visible = true
    },
    close() {
      this.visible = false
    },
    confirm() {
      this.$emit('input', this.imageUrl)
      this.$emit('confirm', this.imageUrl)
      this.close()
    },
    handlePaste(event) {
      event.preventDefault();
      // 获取粘贴事件的 clipboardData
      const clipboardData = event.clipboardData || window.clipboardData;
      console.log(clipboardData)
      let hasImage = false;
      if (clipboardData && clipboardData.items) {
        console.log(clipboardData.items);
        for (const item of clipboardData.items) {
          if (item.type.indexOf('image') !== -1) {
            console.log('图片！！！')
            // 这是一张图片
            const blob = item.getAsFile();
            this.imageUrl = URL.createObjectURL(blob);
            const img = document.getElementById('copyImg');
            img.src = this.imageUrl;
            this.loading = true;
            this.request(blob);
            hasImage = true;
          }
        }
        if (!hasImage) {
          this.$message.error("只支持粘贴图片");
        }
      }
    },
    fileChange(e) {
      const result = e.target.files[0];
      this.request(result);
      let URL = window.URL || window.webkitURL;
      let imgURL = URL.createObjectURL(result);
      this.loading = true;
      this.imageUrl = imgURL;
    },
    request(blob) {
      const formDataBody = new FormData();
      formDataBody.append("file", blob)
      axios.post(environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl, formDataBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'channel': 'platformPc',
          'ACCESS_TOKEN': localStorage.getItem('token'),
          'TOKEN': encryptByDES(encryptByDES((new Date().getTime()).toString(), '63e42c2444e94c1ebca21d30d2aa39a5'), localStorage.getItem('token') || '')
        }
      }).then(r => {
        this.$message.success("上传成功");
        const data = r.data ?? {};
        if (data.code === 1) {
          this.imageUrl = data.orgUrl;
          this.uploadCurrentTab = this.currentTab;
        } else {
          this.loading = false;
          this.$message.error("请务上传过大的图片，且只支持JPG或PNG格式")
          this.imageUrl = "";
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.$message.error("请务上传过大的图片，且只支持JPG或PNG格式");
        this.imageUrl = "";
      })
    },
    preview() {
      this.$viewerApi({
        images: [this.imageUrl],
        options: {
          zIndex: 99999999999,
        }
      })
    },
    deleteImg() {
      this.imageUrl = "";
    }
  },
}
</script>
<style scoped lang="scss">
.box {
  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }

  .list_box {
    padding-left: 15px;
    margin-top: 16px;
    display: flex;
    align-items: center;

    /deep/ .el-upload {
      width: 60px;
      height: 60px;
      border: 1px dashed #C1CBFF;
      transform: translateY(4px);
    }

    >p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-right: 17px;
    }

    >textarea {
      width: 500px;
      height: 60px;
      background: #F9FBFD;
      border: 1px solid #C1D1FF;
      border-radius: 4px;
      padding: 12px;
      margin-left: 16px;
      margin-right: 13px;
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }

    >.delete {
      cursor: pointer;
      color: #FD677D;
      font-size: 24px;
      position: relative;
      top: -50px;
      right: 10px;
    }
  }
}

.disable {
  border-color: #bebebe !important;
  background: #bebebe !important;
  cursor: text !important;
}

.disable_result {
  border-color: #eee !important;
  background: #eee !important;
  cursor: no-drop !important;
}

.upload_img {
  width: 100px;
  height: 100px;
  font-size: 19px;
  text-align: center;
  line-height: 100px;
  color: #BFBFBF;
  cursor: pointer;
  border: 1px solid #DDE5F6;
  background: #EFF1F4;
  border-radius: 4px;
}

.inner {
  width: 100%;
  border: 1px solid #DDE5F6;
}

.tab {
  padding: 10px 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  border: 1px solid #DDE5F6;

  >div {
    cursor: pointer;
    padding: 0 26px;
    margin-right: 10px;
    height: 100%;
    position: relative;

    &::after {
      content: "";
      width: 1px;
      height: 21px;
      background: #E4ECFD;
      position: absolute;
      right: 0;
      top: 2px;
    }

    >img {
      transform: translateY(4px);
      padding-bottom: 10px;
      box-sizing: content-box;
    }
  }

  .active_tab img {
    width: 18px;
    height: 18px;
    color: #444CF1;
    border-bottom: 1px solid #4C84FF;
  }
}

.content {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 100px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  border: 1px solid #0981FF;
}

.content_style {
  text-align: center;
  width: 100%;
  color: #4C84FF;
}


.content_style {
  font-size: 14px;
  color: #666;
  line-height: 100px;
  cursor: pointer;

  p {
    color: #4C84FF;
    font-size: 14px;
  }
}

.copy_img {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.content_upload {
  position: relative;
  cursor: pointer;
}

.content_upload input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.copy_inner {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 160px;
  height: 160px;
  position: relative;
  border: 1px solid #DDE5F6;
  background: #EFF1F4;
  border-radius: 4px;
  margin-top: 21px;
  cursor: pointer;

  .mask {
    display: none;
  }
}

.copy_inner:hover .mask {
  width: 160px;
  height: 160px;
  background: rgba(0, 0, 0, .3);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;

  >i {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
