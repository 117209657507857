<!--tab胶囊切换-->
<template>
  <div class="box" :style="{borderRadius}">
    <div class="item" v-for="(item,index) of tabData"
         :key="index"
         :style="{borderRadius}"
         :class="i === index?'active':''"
         @click.self="i = index"
    >
      <!-- 可能存在等于0的情况 -->
      {{ item.num !== undefined ? `${item.text}(${item.num > 99999 ? '99999+' : item.num}${unitName})` : item }}
    </div>
  </div>
</template>
<script>
export default {
  name: "TabSelect",
  props: {
    // 初始选择索引
    activeIndex: {
      type: Number,
      default: 0
    },
    unitName: {
      type: String,
      default: ''
    },
    // 数据
    tabData: {
      // 数组项text代表文本，num代表数量
      type: Array,
      // 数组需避免引用更新
      default: () => [],
      // 必传
      require: true,
    },
    borderRadius: {
      type: String,
      default: "21px"
    }
  },
  // 初始钩子
  created() {
    this.i = this.activeIndex
  },
  data() {
    return {
      i: 0
    }
  },
  // 侦听器
  watch: {
    // 避免重复数据重新操作
    i(newI) {
      // tab切换执行的额外操作，index方便获取当前tab数据
      this.$emit("tabAction", newI);
    }
  },
}
</script>
<style lang="scss" scoped ref="stylesheet/scss">
.box {
  overflow: hidden;
  display: inline-block;
  box-shadow: 0px 4px 8px 0px rgba(196,197,198,0.2);
  border: 1px solid #D9E6FF;
  border-radius: 21px;
  cursor: pointer;
  align-items: center;

  > .item {
    transition: all .3s;
    float: left;
    width: 160px;
    color: #333;
    box-sizing: border-box;
    align-items: center;
    padding: 8px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border-radius: 20px;
    background: #F9FBFD;
  }

  > .active {
    background: #4C84FF;
    color: #fff;
  }
}
</style>
