<template>
  <div class="el-img-upload">
    <el-upload
      :action="uploadUrl"
      :headers="headers"
      accept=".png,.jpg,.mp4"
      list-type="picture-card"
      :file-list="fileList"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :disabled="uploadDisabled"
      :class="{'hide-upload': uploadDisabled}"
    >
      <!-- :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove" -->
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img v-if="getMediaType(file.url) === 'image'" class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <video v-if="getMediaType(file.url) === 'video'" class="el-upload-list__item-thumbnail">
          <source :src="file.url">
        </video>
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!uploadDisabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-image-viewer
      v-if="showViewer"
      :z-index="99999"
      :on-close="() => {showViewer=false}"
      :url-list="imgList"
    />
    <!--  视频预览  -->
    <div class="video_preview" v-if="videoShow">
      <div class="video_close" @click="videoShow = false">
        <i class="el-icon-close"/>
      </div>
      <video controls autoplay>
        <source :src="videoUrl">
        视频格式不支持
      </video>
    </div>
  </div>
</template>

<script>
import environments from "@/config/url";
import { encryptByDES } from "@/utils/3DES";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
const uploadUrl = environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl;
export default {
  name: "UploadImgOrVideo",
  components: { ElImageViewer },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    elForm: {
      default: ''
    }
  },
  data() {
    return {
      uploadUrl,
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      fileList: [],
      showViewer: false,
      imgList: [],
      videoShow: false,
      videoUrl: ''
    };
  },
  watch: {
    value(v) {
      this.fileList = v.map(item => {
        const findItem = this.fileList.find(it => it.url === item)
        if (findItem) return findItem
        const nameStrArr = item.split("/");
        return {
          url: item,
          name: nameStrArr[nameStrArr.length - 1],
        }
      })
    },
  },
  computed: {
    uploadDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    }
  },
  methods: {
    handleAvatarSuccess(res, file, fileList) {
      file.url = res.fileUrl
      this.fileList = fileList
      this.emitInput()
    },
    beforeAvatarUpload(file) {
      this.headers.TOKEN = encryptByDES(
        encryptByDES(
          new Date().getTime().toString(),
          "63e42c2444e94c1ebca21d30d2aa39a5"
        ),
        localStorage.getItem("token") || ""
      );
      // const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      // const isLt2M = file.size / 1024 / 1024 < this.fileSize;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG或者PNG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error(`上传图片大小不能超过 ${this.fileSize}MB!`);
      // }
      // return isJPG && isLt2M;
    },
    handleRemove(file) {
      const i = this.fileList.findIndex(item => item.url === file.url)
      this.fileList.splice(i, 1)
      this.emitInput()
    },
    emitInput() {
      this.$emit('input', this.fileList.map(item => item.url))
    },
    handlePictureCardPreview(file) {
      if (this.getMediaType(file.url) == 'video') {
        this.videoUrl = file.url
        this.videoShow = true
      } else {
        this.imgList = [file.url];
        this.showViewer = true;
      }
    },
    getMediaType(url) {
      const urlStringArr = url.split('.')
      const fileType = (urlStringArr[urlStringArr.length - 1]).toLocaleLowerCase()
      if (fileType === 'mp4') return 'video'
      return 'image'
    }
  },
};
</script>

<style lang="scss" scoped>
.el-img-upload>div {
  display: flex;
  /deep/.el-upload {
    width: 148px;
    height: 148px;
  }
}
.video_preview {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, .5);
  left: 0;
  top: 0;
  z-index: 99999999999999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .video_close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    background-color: #606266;
    border-radius: 50%;
    cursor: pointer;
  }

  video {
    display: block;
    //margin: 60px auto;
    width: auto;
    height: 85%;
    object-fit: cover;
  }
}
.hide-upload{
  /deep/.el-upload{
    display: none;
  }
}
</style>
